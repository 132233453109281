import { addCustomMsgCallBack, JanusIns, LocalEvType, start as janus } from 'janus-websdk'
import { kbfc } from 'kbfetch'
import { markRaw, reactive, ref } from 'vue'
export const llmModeOn = new URLSearchParams(location.search).get('mode') !== 'no-llm'
export const title = llmModeOn ? '源创AGI 实时互动数字人' : '源创AGI 朗读数字人'
export const toggleLlmMode = () => {
    const query = new URLSearchParams(location.search)
    const newMode = query.get('mode') === 'no-llm' ? '' : 'no-llm'
    query.set('mode', newMode)

    // @ts-ignore
    window.top.location.href = 'https://digital.yunbzw.com/?' + query.toString()
}

const initBody = {
    bussAppId: 98,
    rtcToken: 'linjing@2023',
    rtcAppId: 1,
    // channel: 1010, rtcChannelId: "1010", liveId: 1010,
    // rtcUid: 10, connType: 0,
    rtcUid: 65111004,
    rtcChannelId: '4001',
    channel: 4001,
    liveId: 4001,
    connType: 0,
    rtcRole: 1,
    rtmRole: 1
    // "rtcToken": "054a15b4fac5d0fd0a7c6c63a88d0ac36662a22b"
}
// {"seq":"11","cmd":"swapstartxrlive","response":{"code":200,"codeMsg":"Success","data":{"liveId":405634876696432640,
// "channel":75590,"rtcAppId":2,"rtcUid":10000114,"rtcChannelId":"75590","rtcToken":"a47d8fe9559163f3413f984d0a1ce9db665590ea"}}}
type InitBody = {
    bussAppId: 103 //
    rtcToken: 'linjing@2023'
    rtcAppId: 2
    connType: 0 //
    rtcUid: 65111004
    rtcChannelId: '4001'
    channel: 4001
    liveId: 4001
    peerUid: 35002004
}

export const state = reactive({
    rtcUid: Date.now().toString().slice(-4) + performance.now().toFixed(0).split('').reverse().join(''),
    rtcChannelId: '10203',
    inputMsg: '',
    canShowMsgs: true,
    canShowLoginLog: false,
    bShowSetting: false,
    loginLog: [] as { time: string; info: string }[],
    msgs: [] as { uid: number | '我'; msg: string; time: string }[],
    linkState: 0 as 0 | 1, // 断开,链接/连接中
    remoteVideoStreams: {} as Record<string, MediaStream>,
    videoStreamsMeta: {} as Record<string, InitBody & JanusIns>,
    remoteVideosDisable: {} as Record<string, boolean>,
    remoteAudiosDisable: {} as Record<string, boolean>,
    remoteUids: markRaw({}) as Record<string, string[] | undefined>,
    localStream: { audio: true, video: true },
    role: true
})
export const comIdx = ref(0)

export const openSetting = () => {
    state.bShowSetting = true
}
const remoteAudioStreams = {} as Record<string, MediaStream>
const audioStreamsMeta = {} as Record<string, InitBody & JanusIns>
addCustomMsgCallBack(data => {
    if (!data) return console.error('meid没得data', data)
    if ('ArrayBuffer' === data.constructor.name) {
        // console.log(data, data.byteLength)
        data = new TextDecoder().decode(data)
    }
    if (data.evType === LocalEvType.AUDIO_COMIN) onAudio(data)
    console.error('msg', new Date().toLocaleString(), data)
})

const onAudio = ({ stream, id, initBody, janusIns, isLocal }) => {
    const { peerUid } = initBody
    if (isLocal) return console.error('{islocal}')
    state.remoteUids[peerUid] ||= []
    state.remoteUids[peerUid]!.push(id)
    if (remoteAudioStreams[id]) {
        document.getElementById(id)?.remove()
        console.warn('已经存在了Audio')
    }
    remoteAudioStreams[id] = stream
    audioStreamsMeta[id] = Object.assign(janusIns, initBody) as any

    const audioDom = document.createElement('audio')
    audioDom.id = id
    audioDom.srcObject = stream
    audioDom.autoplay = true
    audioDom.muted = true
    audioDom.style.visibility = 'hidden'

    audioDom.onplay = () => {
        console.error('====>音频来了 callback 波地方')

        audioDom.muted = false
    }
    document.body.appendChild(audioDom)
    console.error('====>音频来了 波地方')
}

let janusIns: ReturnType<typeof janus>
const sleep = (timeout) =>
    new Promise(resolve =>
        setTimeout(() => {
            resolve(0)
        }, timeout)
    )
const getChannelId = async () => {
    // 1表示服务实例正在启动
    let code = 1
    let channel = '10203'
    // 设置一个5分钟超时时间
    const outtime = Date.now() + 5 * 60 * 1000
    while (code === 1) {
        if (Date.now() > outtime) {
            code = -1
        }
        const res = await kbfc.post('https://testws.yunbzw.com/syncinfmgr/getInferenceChannel', { appId: '123', taskId: state.rtcUid + '', character: '19C' })
        code = res.code
        channel = res.channel || channel
        if (code === 1) await sleep(3000)
    }
    return channel
}
const onClose = async () => {
    // delete options.onRemoteTrackClose
    await sleep(4000)
    if (janusIns.original.isConnected()) return
    confirm('服务端链接断开是否重试?')
    location.reload()
}
const start = async () => {
    let { rtcUid, rtcChannelId, role } = state

    console.error({ role })
    const _role = role ? 1 : 0
    if (!rtcChannelId || !rtcUid) return console.error('参数不完整')
    rtcChannelId = await getChannelId()
    state.linkState = 1
    Object.assign(initBody, {
        rtcUid: +rtcUid,
        rtcChannelId,
        channel: +rtcChannelId,
        liveId: +rtcChannelId,
        rtcRole: _role,
        rtmRole: _role
    })
    console.log('初始加入参数', initBody)
    const options: Parameters<typeof janus>[0] = {
        initBody,
        localVideoContainer: document.getElementById('local'),
        remoteVideoContainer: document.getElementById('remote'),
        server: 'https://ycrt.yunbzw.com/facertc/linjing',
        localAudioTrackOption: { capture: false },
        localVideoTrackOption: { capture: false },
        onClose
    }
    janusIns = janus(options)
    janusIns.whenChannelOpened.then(() => updateLoginlog())
}

const sendMsg = (data = state.inputMsg) => {
    // {"evType":5,"uid": 19, "msg": "gogogo"}
    const body = { evType: 7, data }
    state.msgs.push({ uid: '我', msg: data, time: new Date().toLocaleTimeString() })
    // state.inputMsg = ''
    log('发哦送那个body', body)
    janusIns.sendText(JSON.stringify(body))
}

const updateLoginlog = () => {
    const entries = Object.entries(localStorage).filter(it => it[0].startsWith('id_'))
    state.loginLog = entries.map(([key, info]) => [+key.split('_')[1], info]).sort((b, a) => a[0] - b[0]).map(([key, info]) => ({ info, time: new Date(key).toLocaleString() }))
}

const log = (...text) => console.warn('tts_', ...text)
const sessionIdSet = new Set()
let sessionId = ''
let sessionText = ''
let partCache = ''
window.addEventListener('message', ({ data }) => {
    const endFlag = '__ __'
    log('收到数据', data)
    const idx = data.indexOf(';')
    const [cmd, id] = data.slice(0, idx).split('^')
    const params = data.slice(idx + 1)
    if (cmd === 'ttsText') {
        if (id !== sessionId) {
            const oldsize = sessionIdSet.size
            sessionIdSet.add(id)
            if (oldsize === sessionIdSet.size) {
                // python框架可能会用旧的id发送数据
                console.error('添加失败', id, sessionIdSet)
                return Promise.reject('tian添加失败')
            }
            sessionId = id
            sessionText = ''
            partCache = ''
        }

        if (params === endFlag) {
            sessionId = ''
            log({ sessionText })
        } else {
            sessionText += params
            partCache += params
        }
        if (sessionText.length > 500) return log('超出字数限制不发送')
        if (partCache.includes('。') || partCache.includes('，') || params === endFlag) {
            console.log('包含', partCache)
            partCache = partCache.trim()
            const statements = partCache.split(/[。，]/g)
            partCache = statements.length > 1 ? statements.pop() || '' : ''
            sendMsg(statements.join(','))
            if (params === endFlag && partCache) {
                sendMsg(partCache)
                partCache = ''
            }
        }
    }
}, false)
window.addEventListener('load', () => {
    console.log('加载完成')
    start()
})
export const fileIdx = ref(1)
export const onFinish = (msg) => {
    comIdx.value = 1
    document.querySelector('audio')?.play()

    // janusIns.sendText(JSON.stringify({ evType: 7, data: '请稍等,正在全力初始化中...' }))
    const data = llmModeOn ? '你好，我是数字招聘官，请问有什么可以帮到你吗？' : '你好,我是文本朗读数字人,请将您的文字输入到下方，我将为您朗读。'
    janusIns.sendText(JSON.stringify({ evType: 7, data }))

    state.inputMsg = msg
    sendMsg()
    // @ts-ignore
    window.top.postMessage('showChat', '*')
}
// @ts-ignore
window.sendText = data => janusIns.sendText(JSON.stringify({ evType: 7, data }))
